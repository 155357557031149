@charset "utf-8";
@import './syntax.css';

// Our variables
$base-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-font-size: 14px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$header-font-family: Georgia1, Georgia, serif;

$spacing-unit: 12px;

$text-color: #111;
$background-color: #fdfdfd;
$brand-color: #2a7ae2;

$link-color: #254edb;

$grey-color: #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark: darken($grey-color, 25%);

// Width of the content area
$content-width: 80ch;

$on-palm: 600px;
$on-laptop: 800px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@import 'layout', 'style';

// Import partials from `sass_dir` (defaults to `_sass`)
// @import
//         "base",
//         "layout",
//         "syntax-highlighting"
// ;
