body {
  max-width: $content-width;
  margin: 0 auto;
  padding-top: $spacing-unit * 6;
}

header {
  margin-top: $spacing-unit;
}

main {
  padding: $spacing-unit;
}

/* Demo Videos */
video,
img {
  display: block;
  margin: 0 auto;
}
