body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  color: $text-color;
}

header {
  font-family: $header-font-family;
  margin-top: $spacing-unit;
  text-align: center;
  font-size: ($base-font-size + 1);

  &.honcho {
    font-size: ($base-font-size + 2);
  }

  a {
    text-decoration: none;
    color: $text-color;
  }

  a:hover {
    text-decoration: underline;
  }
}

main {
  line-height: $base-line-height;
}

/* Highlight link target */
:target {
  background-color: lightyellow;
  color: black;
}

a {
  color: $link-color;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.blog-img {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.post-date {
  font-size: ($base-font-size - 1);
  color: ($grey-color);
  margin-left: $spacing-unit;
}

.post-private-disc {
  color: lightyellow;
  background-color: black;
  padding: 4px;
  border-radius: 4px;
}

.mainproject {
  font-weight: bold;
  margin-bottom: $spacing-unit;
}

footer {
  font-size: ($base-font-size - 1);
  margin-left: ($spacing-unit * 3);
  margin-bottom: $spacing-unit;
}
